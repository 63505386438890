import React from "react"
import { Link } from "gatsby"

import { getCategoryUrl } from "components/utils"
import { useBlogCategories } from "src/hooks/use-blog-categories"
import * as s from "./blog-categories.module.sass"

const BlogCategories = () => {
  const categories = useBlogCategories()

  return (
    <div className={s.component}>
      <ul className={s.categories}>
        <li className={s.item}>
          <Link to="/blog/" className={s.link} activeClassName={s.active}>
            #All posts
          </Link>
        </li>

        {categories.map((category) => {
          if (!category.posts || category.posts.length < 1) {
            return
          }

          const to = getCategoryUrl(category.slug)

          return (
            <li key={category.slug} className={s.item}>
              <Link to={to} className={s.link} activeClassName={s.active}>
                #{category.title}
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default BlogCategories
