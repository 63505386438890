import React from "react"

import Heading from "components/elements/heading"
import BlogCategories from "components/blog/blog-categories"

import * as s from "./blog-header.module.sass"

const BlogHeader = () => {
  return (
    <div className={s.component}>
      <Heading
        parentClass={s.heading}
        surtitle="What's happening"
        title="Our blog"
      />

      <BlogCategories />
    </div>
  )
}

export default BlogHeader
