import { useStaticQuery, graphql } from "gatsby"

export const useBlogCategories = () => {
  const { strapi } = useStaticQuery(
    graphql`
      query BlogCategories {
        strapi {
          categories {
            ...categoryFields
          }
        }
      }
    `
  )
  return strapi.categories
}

export const PostFragment = graphql`
  fragment categoryFields on Strapi_Category {
    id
    slug
    title
    posts {
      id
    }
  }
`
