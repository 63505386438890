import React from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"

import Layout from "layouts/default"
import Header from "components/components/header"
import BlogHeader from "components/blog/blog-header"
import Posts from "components/blog/posts"

const BlogCategoryTemplate = (props) => {
  const { data } = props
  const posts = data.strapi.posts || []

  return (
    <Layout>
      <Helmet>
        <title>Netspeak Games Blog</title>
      </Helmet>
      <Header />
      <BlogHeader />
      <Posts posts={posts} />
    </Layout>
  )
}

export const query = graphql`
  query PostsByCategoryQuery($categorySlug: String) {
    strapi {
      posts(where: { isPublished: true, category: { slug: $categorySlug } }) {
        ...postFields
      }
    }
  }
`

export default BlogCategoryTemplate
